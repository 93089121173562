@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.aspire2b-admin .MuiBox-root button, 
.imageUploadContainer label, 
.MuiModal-root button.createProgram
{
  text-transform: capitalize;
  border-radius: 24px;
}
.MuiList-root .mainMenu {
  border-left: 5px solid #ffffff;
  padding: 8px 16px 8px 32px;
}
.MuiList-root .mainMenu.Mui-selected {
  background-color: transparent!important;
  color: #005CBC;
  border-left: 5px solid #005CBC
}
.MuiList-root .mainMenu.Mui-selected span {
  color: #005CBC;
}
.MuiList-root .mainMenu.Mui-selected svg {
  color: #005CBC;
}
.MuiList-root .mainMenu .MuiListItemIcon-root {
  min-width: 40px;
  font-size: 16px;
  font-weight: 700;
}
.MuiList-root .mainMenu span {
  font-weight: 700;
  color: #616161;
}
.MuiMenu-paper{
  box-shadow: none;
  border: 1px solid #dddddd;
  width: 180px;
  margin-top: 4px;
}
.MuiMenu-list .MuiMenuItem-root {
  font-size:14px;
}
.inputSelectColor div:hover:before {
  border-bottom: none!important;
}
.inputSelectColor div:hover:after {
  border-bottom: none!important;
}
.inputSelectColor div:before {
  border-bottom: none;
}
.inputSelectColor div:after {
  border-bottom: none;
}
.inputSelectColor div input {
  width: 40px;
  height:40px;
  border-radius:8px;
}
.form-title {
  font-size: 14px;
  color: #616161;
  font-weight: 700;
  padding: 16px 0;
}
.form-subtitle {
  font-size: 14px;
  color: #616161;
  font-weight: 400;
  padding: 4px 0;
}
.MuiSnackbar-root{
  bottom: 48px!important;
}
.MuiSnackbarContent-root {
  background-color: #DFEAF9!important;
  border-radius: 16px!important;
}
.MuiSnackbarContent-root .MuiSnackbarContent-message {
  color: #171717;
  font-size: 14px;
  font-weight: bold;
  padding: 12px;
}
.aspire2b-admin .scroll-form-accordion {
  overflow: scroll;
  height: calc(100vh - 190px);
  padding-bottom: 100px;
}
.aspire2b-admin .subtitle-accordion {
  font-size: 14px;
  color: #616161;
  padding: 16px 0;
}
.aspire2b-admin .subtitle-accordion-form {
  font-size: 14px;
  color: #616161;
  padding: 8px 0;
}
.aspire2b-admin .menu-divide {
  margin-left: 47px;
  background: #888888;
  width: 2px;
  height: 22px;
}
.aspire2b-admin .menu-item {
  font-size: 16px;
  font-weight: 700;
}
.aspire-date .MuiInputBase-root {
  width: 180px;
  height: 36px;
}
.ck-powered-by-balloon {
  display:none!important;
}
.ck-editor__editable_inline {
  max-height: 200px;
}
ul, ol {
  padding-left: 16px;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial.ttf') format('truetype');
}

@font-face {
  font-family: 'Monospace';
  src: url('../fonts/MonoSpace.ttf') format('truetype');
}

@font-face {
  font-family: 'Serif';
  src: url('../fonts/Serif.ttf') format('truetype');
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('../fonts/SpaceGrotesk.otf') format('opentype');
}

@font-face {
  font-family: 'Gatorade';
  src: url('../fonts/Gatorade.otf') format('opentype');
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy.ttf') format('truetype');
}

@font-face {
  font-family: 'Quaker';
  src: url('../fonts/Quaker.otf') format('opentype');
}

@font-face {
  font-family: 'Segoe';
  src: url('../fonts/Segoe.ttf') format('truetype');
}