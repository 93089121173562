

.authoring-banner-image-container {
  font-size: 16px;
}
.authoring-banner-image-container .imageUploadContainer {
    padding: 0;
}

.authoring-banner-image-container .uploadImageFrame {
    width: 100%;
    height: auto;
    background: #EFF5FC;
    border-radius: 8px;
    border: 1px dashed #1976d2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: "#00ff00";
}

.uploadSmallImageFrame {
  width: 100px;
  height: auto;
  background: #EFF5FC;
  border-radius: 8px;
  border: 1px dashed #1976d2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: "#00ff00";
}

.authoring-banner-image-container .uploadCloudIcon {
    font-size: 48px;
}

.authoring-banner-image-container .replaceBtnGridItem {
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
}    
.authoring-banner-image-container .replaceBtnGridItem i {
    margin: 24px 0 0 28px;
    font-size: 18px;
    font-weight: bold;
    color: "#0000ff";
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 8px;
}

.authoring-banner-image-container .replaceImageButton {
  margin-top: 24px;
}
.authoring-banner-image-container .replaceImageButtonRemove {
  margin-left: 24px;
  margin-top: 24px;
}


.authoring-banner-image-container .imageAlignmentFormControl {
   padding-top: 24px;
 }

.authoring-banner-image-container .helpfulText {
  font-size: 15px;
  color: #ff0000;
  margin-top: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.authoring-banner-image-container .imageContainer {
  position: relative;
  height: 150px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authoring-banner-image-container .deleteIcon {
  position: absolute;
  top: -15px;
  right: 77px;
  z-index: 1;
  color: grey;
}

.authoring-banner-image-container .uploadedAvatar {
  width: 100px;
  height: 100px;
  border: 1px solid;
}

